import React, {
    createContext,
    useContext,
    useEffect,
    useState,
  } from "react";
import { LOCAL_STORAGE_KEYS } from "../utils/CONSTANTS.js";
import { useLS } from "./LsContext.js";
  
export const wizardContext = createContext();
  
const useProvideWizard = () => {
  const [wizardItems, setWizardItems] = useState([]);
  
  const saveValueOrReplaceWizardItem = (data, id) => {
    const oldState = [...wizardItems];
    const existingIndex = oldState.findIndex(el => el && el.hasOwnProperty(id));
    
    if (existingIndex !== -1) {
      oldState[existingIndex] = {[id]: data};
    } else {
      oldState.push({[id]: data});
    }
    
    setWizardItems(oldState);
  }

  useEffect(() => {
    console.log('Wizard context initialized')
  }, []);

  return {
      wizardItems, 
      setWizardItems, 
      saveValueOrReplaceWizardItem
  };
};
  
export const WizardProvider = ({ children }) => {
  const wizard = useProvideWizard();
  return (
    <wizardContext.Provider value={wizard}>{children}</wizardContext.Provider>
  );
};
  
export const useWizard = () => useContext(wizardContext);
  